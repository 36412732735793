<template>
  <div>
    <c-search-box ref="searchbox" @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            defaultStart="-6M"
            defaultEnd="6M"
            label="평가기간"
            name="assessmentDates"
            v-model="searchParam.assessmentDates"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            codeGroupCd="RAM_ASSESS_TYPE_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="ramAssessTypeCd"
            label="평가구분-정기/수시/최초"
            v-model="searchParam.ramAssessTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            codeGroupCd="RAM_TECHNIQUE_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="ramTechniqueCd"
            label="위험성평가 분류"
            v-model="searchParam.ramTechniqueCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="RiskTable"
      title="위험성 평가 목록"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      :selection="popupParam.type"
      rowKey="ramRiskAssessmentPlanId"
      @rowDblclick="rowDblclick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
          <c-btn v-if="editable" label="LBLSELECT" icon="check" @btnClicked="select" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'chem-material-pop',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        plantCd: '',
        ramRiskAssessmentPlanId: '',
      }),
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      listUrl: '',
      grid: {
        columns: [
        {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:80px',
            sotrable: true
          },
          {
            name: 'assessmentName',
            field: 'assessmentName',
            label: '평가명',
            align: 'left',
            style: 'width:250px',
            sortable: true,
          },
          {
            name: 'ramStepName',
            field: 'ramStepName',
            label: 'LBLSTEPPROGRESS',
            align: 'center',
            style: 'width:100px',
            sortable: true
          },
          {
            name: 'assessmentDate',
            field: 'assessmentDate',
            label: '평가기간',
            align: 'center',
            style: 'width:200px',
            sortable: true
          },
          {
            name: 'ramTechniqueName',
            field: 'ramTechniqueName',
            label: '평가구분',
            align: 'center',
            style: 'width:100px',
            sortable: true
          },
        ],
        data: [],
        height: '100%'
      },
      searchParam: {
        plantCd: null,
        assessmentDates: [],
        ramAssessTypeCd: null,
        ramStepCd: null,
        ramTechniqueCd: null,
      },
      editable: true,
      searchUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  }, 
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    contentHeight() {
      let height = (this.contentHeight - this.$refs['searchbox'].$el.offsetHeight - 50);
      if (height < 400) {
        height = 500;
      }
      this.grid.height = height + 'px'
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.ram.assessPlan.list.url
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    select() {
      let selectData = this.$refs['RiskTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '위험성 평가를 선택하세요.', // 위험성 평가를 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    rowDblclick(row) {
      if (this.popupParam.type === 'single') {
        this.$emit('closePopup', [row]);
      }
    },
  }
};
</script>
